import React from "react"
import content from "../../content/mainTitle.yaml"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Modal from "react-modal"
import {ButtonBase} from "@material-ui/core";

Modal.setAppElement("#___gatsby")

export default () => {
    const {t} = useTranslation('mainTitle');
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const openModal = () => {
        setIsOpen(true);
        setIsLoading(true);
        initializeHubspotForm();
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'unset';
    }

    const redirect = () => window.open("https://signup.mendix.com/link/signup/?source=direct", "_blank", "norefferer noopener");

    const initializeHubspotForm = async () => {
        if("hbspt" in window){
            console.log("window", window);
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25577968",
                formId: "8dcec37d-a5cf-413a-93d4-4b49cf48e87a",
                target: "#form-container",
                onFormReady: () => setIsLoading(false),
                onFormSubmitted: redirect
            });
        }else{
            setTimeout(initializeHubspotForm, 500);
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
            height: "90%",
            maxWidth: "790px",
            maxHeight: "900px",
            padding: 30,
        },
        overlay:{
            zIndex: 999
        }
    }

    return (
        <section id="hero">
            <ul>
                <li id="hero__bg__blur" className="hero__bg__layer bg-image-0">
                </li>
            </ul>
            <div className="row">
                <div className="twelve columns" style={{marginTop: '90px'}}>

                    <div className="hero-text">
                        <h1 className="responsive-headline">MENDIX</h1>
                        <h1 className="responsive-headline">{t('Headline')}</h1>
                        <p>{t(content.body)}</p>
                    </div>

                    {/*<div className="buttons">*/}
                    {/*    <a style={{color: 'black'}} target="_blank" rel="noopener noreferrer"*/}
                    {/*       className="btn-primary white" href={content.button1.to}>{t(content.button1.label)}</a>*/}
                    {/*</div>*/}
                    <div className="buttons">
                        <button style={{color: 'black'}} onClick={openModal}
                                className="btn-primary white">{t(content.button1.label)}</button>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Kontaktirajte nas"
                className="ReactModal__Content-custom"
            >
                <div className={"contact-title-box"}>
                    <div className="spacer"/>
                    <div style={{textAlign: 'center', marginBottom: '40px', fontSize: '30px'}}>Kontaktirajte nas</div>
                    <ButtonBase className="btn-close" onClick={closeModal}>
                        Zapri&nbsp;X
                    </ButtonBase>
                </div>
                {isLoading && <div className="loader"></div>}
                <div id="form-container"></div>
            </Modal>
        </section>
    )
}

