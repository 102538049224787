import React from "react"
import content from "../../content/call-to-action.yaml"
import {useTranslation} from "react-i18next";
import {ButtonBase} from "@material-ui/core";
import Modal from "react-modal";

Modal.setAppElement("#___gatsby")

export default () => {
  const {t} = useTranslation('call-to-action');
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const openModal = () => {
        setIsOpen(true);
        setIsLoading(true);
        initializeHubspotForm();
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'unset';
    }

    const initializeHubspotForm = async () => {
        if("hbspt" in window){
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25577968",
                formId: "8dcec37d-a5cf-413a-93d4-4b49cf48e87a",
                target: "#form-container",
                onFormReady: () => setIsLoading(false)
            });
        }else{
            setTimeout(initializeHubspotForm, 500);
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
            height: "90%",
            maxWidth: "790px",
            maxHeight: "900px",
            padding: 30,
        },
        overlay:{
            zIndex: 999
        }
    }

  return (
      <section id="call-to-action">

        <div className="row" style={{textAlign: 'center', paddingBottom: '50px'}}>
          <div className="twelve columns">
            <div className="hero-text">
              <h1 className="responsive-headline">{t(content.text)}</h1>
              <p>{t(content.body)}</p>
            </div>
          </div>

          {/*<div className="twelve columns action">*/}
          {/*  <a target="_blank" rel="noopener noreferrer" style={{color: 'black', marginBottom: '20px'}}*/}
          {/*     className="btn-primary white" href="https://signup.mendix.com/">{t(content.buttonText1)}</a>*/}

          {/*  /!*<a target="_blank" rel="noopener noreferrer" style={{marginLeft: '10px'}}*!/*/}
          {/*  /!*   className="btn-primary-outlined white" href="https://www.mendix.com/platform/">{t(content.buttonText2)}</a>*!/*/}
          {/*</div>*/}
            <div className="buttons">
                <button style={{color: 'black'}} onClick={openModal}
                        className="btn-primary white" >{t(content.buttonText1)}</button>
            </div>
            <Modal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Kontaktirajte nas"
                className="ReactModal__Content-custom"
            >
                <div className={"contact-title-box"}>
                    <div className="spacer"/>
                    <div style={{textAlign: 'center', marginBottom: '40px', fontSize: '30px'}}>Kontaktirajte nas</div>
                    <ButtonBase className="btn-close" onClick={closeModal}>
                        Zapri&nbsp;X
                    </ButtonBase>
                </div>
                {isLoading && <div className="loader"></div>}
                <div id="form-container"></div>
            </Modal>
        </div>
      </section>
  )
}
