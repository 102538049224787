import React from "react"

import Layout from "../components/layout"

import MainTitle from "../components/mainTitle"
import Section1 from "../components/section1"
import Features from "../components/features"
import Leaders from "../components/leaders"
import Customers from "../components/customers"
import CallToAction from "../components/call-to-action"
import Blog from "../components/blog";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

export default () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "mainTitle": MainTitle,
    "section1": Section1,
    "features": Features,
    "leaders": Leaders,
    "customers": Customers,
    "blog": Blog,
    "call-to-action": CallToAction
  }

    return (
        <>
            <Helmet>
                <script>{`
                    window.jQuery = window.jQuery || (() => ({
                      // these are all methods required by HubSpot
                      change: () => {},
                      trigger: () => {},
                    }));`}
                </script>

                <script charSet="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/v2.js"/>
            </Helmet>
            <Layout>
                {sections.map((section, index) => {
                    let Sections = availableSections[section]
                    return <Sections key={index}/>
                })}
            </Layout>
        </>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
