import React from "react"
import content from "../../content/customers.yaml"
import {useTranslation} from "react-i18next";
export default () => {
  const {t} = useTranslation('customers');
  
  return (

      <section id='features'>
        {content.map((value, index) => {
          let pos = "left"
          return (
              <div key={index} className={'row feature ' + value.name}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '40px'}}
                     className={'twelve columns ' + pos}>
                  <h2 style={{fontSize: '4rem', lineHeight: '1.125', fontWeight: 'bold'}}>{t(value.title)}</h2>
                  <p style={{fontSize: '2.25rem', lineHeight: '1.5'}}>{t(value.body)}</p>
                  {/*<div className="buttons">*/}
                  {/*  <a target="_blank" rel="noopener noreferrer" className="btn-primary2" style={{color: 'white'}}*/}
                  {/*     href={'https://www.mendix.com/customer-stories/'}>{t(value.buttonText)}</a>*/}
                  {/*</div>*/}
                </div>
                {/*<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}*/}
                {/*     className={'six columns ' + pos}>*/}
                {/*  <div className="pricing-tables">*/}
                
                {/*    {value.customers.map((customer, index) =>*/}
                {/*        <a href={customer.url} key={index} target="_blank" rel="noopener noreferrer"*/}
                {/*           className="headshot" style={{backgroundImage: `url(${customer.image})`}}>*/}
                {/*          <div className="headshot_info">*/}
                {/*            <h3 className="bold" style={{color: 'white'}}>*/}
                {/*              {t(customer.name)} </h3>*/}
                {/*            <p className="mt0 customer-description">*/}
                {/*              {t(customer.desc1)}*/}
                {/*            </p>*/}
                {/*            <p className="mt0">*/}
                {/*              {t(customer.desc2)}</p>*/}
                {/*          </div>*/}
                {/*          <img className="arrow-box lazy active" width="82" height="48" alt=""*/}
                {/*               src="https://www.mendix.com/wp-content/themes/mendix/ui/icons/arrow-box.svg"/>*/}
                {/*        </a>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
          )
        })}
      </section>
  )
}
