import React from "react"
import {useSiteMetadata} from "../hooks/use-site-metadata"


export default () => {

    React.useEffect(() => {
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            document.addEventListener('scroll', handleScroll)
        }
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        const navbar = document.querySelector('.nav-fixed');

        if (window.scrollY > 0) {
            navbar.classList.add('nav-active');
        } else {
            navbar.classList.remove('nav-active');
        }
    }


    return (
        <header className="nav-fixed">
            <div className="logo">
                <a target="_blank" rel="noreferrer" href="https://ineor.si/">
                    <img alt="" src={useSiteMetadata().logo}/>
                </a>
            </div>
            {/*<LanguageSwitcher />*/}
        </header>
    )
}
