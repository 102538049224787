import React from "react"
import content from "../../content/features.yaml"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
export default () => {
    const {t} = useTranslation('features');
  library.add(fas)

  return (
    <section id="pricing" style={{background: '#F3F3F4', paddingTop: '60px', paddingBottom: '60px'}}>
      <div className="row section-head" style={{maxWidth: '970px'}}>
        <h2 style={{fontSize: '4rem'}}>{ t(content.title) }</h2>
        <p>{ t(content.body) }</p>
      </div>

      <div className="row" style={{paddingBottom: '0px'}}>
        <div style={{display: 'contents'}} className="pricing-tables bgrid-thirds s-bgrid-halves">
          { content.features.map((feature, index) =>
            <div key={index} className="column">
              <div className="card">
                  <img style={{filter: 'hue-rotate(163deg)'}} className="it-icon-collab lazy post-48929 active" alt="" loading="lazy"
                       src={feature.logo} />
                  <h3 className="heading5 mt1">{t(feature.title)}</h3>
           
                  <div className="mt50">{t(feature.description)}
                  </div>
                  
                  <a target="_blank" rel="noopener noreferrer" href={feature.url}
                     className="card-arrow-link" data-event="homepage">{t(feature.linkTitle)}
                      <svg className="card-arrow-svg" fill="none" height="20" viewBox="0 0 22 17" width="22"
                           xmlns="http://www.w3.org/2000/svg">
                          <path className="arrow-svg-path" fill="#0a1325"
                                d="m21.1095 9.47096c.4164-.41644.4164-1.09164 0-1.50808l-6.7864-6.78639c-.4165-.41645-1.0917-.41645-1.5081 0-.4164.41644-.4164 1.09164 0 1.50808l6.0323 6.03235-6.0323 6.03238c-.4165.4164-.4165 1.0916 0 1.5081.4164.4164 1.0916.4164 1.5081 0zm-20.964931.31234h20.210831v-2.13276h-20.210831z">
                          </path>
                      </svg>
                  </a>
             
              </div>
            </div>
          )
          }
        </div>
      </div>
    </section>
  )
}
