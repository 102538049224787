import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"

import content from '../../content/footer.yaml'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation('footer');
    const { address, contacts } = useSiteMetadata()

    library.add(fas)

    return (
        <footer>
            <div className="row"  style={{maxWidth: '500px', paddingBottom: '0px'}}>
                <div className="twelve columns right-cols">
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        <div >
                            <FontAwesomeIcon style={{margin: '0 auto', marginBottom: '10px'}} icon={ content.locationIcon } />
                            <h3 className="address">{content.company}</h3>
                            <p style={{color: "#8b939c"}}>
                                <a target="_blank" rel="noopener noreferrer" style={{color: "#8b939c"}} href="https://www.google.com/maps/place/Teslova+ulica+30,+1000+Ljubljana/@46.043074,14.4888813,17z/data=!3m1!4b1!4m5!3m4!1s0x47652d425c631cc5:0x1acc57c2842038ea!8m2!3d46.0430703!4d14.49107">  { address.line1 }<br /></a>
                                { address.line2 }<br />
                                { address.line3 }
                            </p>
                        </div>
                        <div style={{margin: '0px 10px'}}></div>
                        <div>
                            <FontAwesomeIcon style={{margin: '0 auto', marginBottom: '10px'}} icon={ content.emailIcon }/>
                            <h3 className="contact">{content.contact}</h3>
                            <ul>
                                { contacts.map((contact, index) =>
                                    <li  key={index}><a style={{color: "#8b939c"}} href={ contact.url }>{ contact.text }</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        <div style={{cursor: 'pointer'}}>
                            <a target="_blank" rel="noopener noreferrer" href="https://ineor.si/legal-notice.html">{t(content.legal)}</a>
                        </div>
                        <div style={{margin: '0px 10px'}}></div>
                        <div style={{cursor: 'pointer'}}>
                            <a target="_blank" rel="noopener noreferrer" href="https://ineor.si/privacy-policy.html">{t(content.privacy)}</a>
                        </div>
                    </div>

                    <br />
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        <div style={{color: "#8b939c"}}>
                            {content.copyright}
                        </div>
                    </div>

                </div>

                <div id="go-top">
                    <button title="Back to Top" onClick={() => scrollTo('#top')}>
                        <FontAwesomeIcon icon={ faChevronUp } />
                    </button>
                </div>
            </div>
        </footer>

    )}
