import React from "react"
import content from "../../content/leaders.yaml"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import {useI18next} from 'gatsby-plugin-react-i18next';

const SvgElement = () =>  <svg id="laurels-svg" className="laurels-svg" fill="none" height="55" viewBox="0 0 25 55" width="25"
                               xmlns="http://www.w3.org/2000/svg" >
    <g className="laurels-svg__color" stroke="#0cf049">
        <path 
            d="m.847839 53.7493c3.269561-1.5191 6.330431-3.5908 8.973911-6.2148 11.68695-11.6701 12.24345-30.1074 1.60005-42.39897"></path>
        <path 
            d="m13.8564 7.75957c.8837 0 1.6-1.63856 1.6-3.65984s-.7163-3.65985-1.6-3.65985c-.8836 0-1.6 1.63857-1.6 3.65985s.7164 3.65984 1.6 3.65984z"></path>
        <path
            d="m9.6825 9.83119c2.0363 0 3.687-.71107 3.687-1.58823s-1.6507-1.58824-3.687-1.58824c-2.03625 0-3.68696.71108-3.68696 1.58824s1.65071 1.58823 3.68696 1.58823z"></path>
        <path 
            d="m19.2414 10.1782c.4384-1.97398.0942-3.7273-.7688-3.91615-.8629-.18886-1.9179 1.25828-2.3563 3.23226-.4384 1.97399-.0942 3.72729.7687 3.91619.863.1888 1.918-1.2583 2.3564-3.2323z"></path>
        <path 
            d="m16.3198 13.5971c.1903-.8567-1.2676-1.9039-3.2562-2.3391s-3.75488-.0935-3.94513.7631c-.19025.8567 1.26763 1.9039 3.25623 2.3391s3.7549.0935 3.9451-.7631z"></path>
        <path 
            d="m21.5597 16.4001c.8481-1.8375.8844-3.6233.081-3.9887-.8033-.3653-2.1421.8282-2.9902 2.6657s-.8843 3.6233-.081 3.9887c.8033.3653 2.1421-.8282 2.9902-2.6657z"></path>
        <path 
            d="m18.0273 19.2871c.368-.7975-.8343-2.1264-2.6855-2.9682-1.8511-.8419-3.6501-.8779-4.0182-.0805-.368.7974.8343 2.1263 2.6854 2.9682 1.8512.8419 3.6502.8779 4.0183.0805z"></path>
        <path
            d="m22.6038 23.2779c1.2718-1.5784 1.7434-3.3021 1.0534-3.85-.6901-.5479-2.2805.2875-3.5523 1.866-1.2719 1.5784-1.7435 3.3021-1.0535 3.8499.6901.5479 2.2805-.2875 3.5524-1.8659z"></path>
        <path 
            d="m18.7379 25.2765c.552-.6849-.2896-2.2637-1.8798-3.5262-1.5901-1.2624-3.3265-1.7306-3.8785-1.0456-.5519.6849.2897 2.2637 1.8798 3.5262 1.5901 1.2624 3.3266 1.7306 3.8785 1.0456z"></path>
        <path
            d="m22.6201 29.3915c1.4602-1.4086 2.1447-3.0605 1.5288-3.6895-.6158-.629-2.2987.003-3.7589 1.4116-1.4602 1.4087-2.1447 3.0605-1.5289 3.6895s2.2988-.003 3.759-1.4116z"></path>
        <path 
            d="m18.5112 30.9083c.6336-.6112-.0031-2.2818-1.4221-3.7313-1.4191-1.4494-3.0832-2.1289-3.7168-1.5176-.6337.6113.003 2.2819 1.4221 3.7313 1.419 1.4495 3.0831 2.1289 3.7168 1.5176z"></path>
        <path 
            d="m21.2352 36.166c1.7196-1.0824 2.7301-2.5604 2.2569-3.3011-.4732-.7408-2.2509-.4638-3.9705.6186-1.7196 1.0823-2.73 2.5603-2.2568 3.3011.4732.7407 2.2508.4638 3.9704-.6186z"></path>
        <path 
            d="m16.9311 36.8178c.7463-.4697.4673-2.2343-.6231-3.9412-1.0905-1.707-2.5794-2.71-3.3256-2.2403-.7463.4697-.4673 2.2343.6232 3.9413 1.0904 1.7069 2.5793 2.7099 3.3255 2.2402z"></path>
        <path 
            d="m18.2207 42.6416c1.9513-.5778 3.3284-1.7277 3.0757-2.5683-.2526-.8406-2.0393-1.0536-3.9906-.4757s-3.3284 1.7278-3.0758 2.5684c.2527.8406 2.0393 1.0535 3.9907.4756z"></path>
        <path 
            d="m13.9379 42.0632c.8468-.2508 1.0613-2.0244.4791-3.9613-.5821-1.937-1.7405-3.304-2.5873-3.0532s-1.0614 2.0243-.4792 3.9613 1.7406 3.3039 2.5874 3.0532z"></path>
        <path 
            d="m14.4242 48.217c2.0272-.1899 3.6034-1.0517 3.5204-1.925s-1.7937-1.4274-3.821-1.2375c-2.0273.1898-3.6035 1.0517-3.5205 1.925s1.7938 1.4273 3.8211 1.2375z"></path>
        <path 
            d="m10.3042 46.8267c.8798-.0824 1.4379-1.7806 1.2466-3.793-.1912-2.0123-1.0595-3.5769-1.93922-3.4945-.87977.0824-1.43792 1.7805-1.24665 3.7929.19126 2.0124 1.0595 3.5769 1.93927 3.4946z"></path>
        <path 
            d="m13.2143 51.5409c.0691-.8744-1.5205-1.7115-3.55046-1.8695-2.02993-.1581-3.73154.4226-3.80065 1.2971-.0691.8744 1.52047 1.7114 3.55041 1.8695 2.0299.1581 3.7315-.4227 3.8007-1.2971z"></path>
        <path 
            d="m7.51295 47.2305c.15924-2.015-.42579-3.7041-1.30671-3.7727s-1.72413 1.5093-1.88337 3.5243c-.15923 2.015.4258 3.7041 1.30671 3.7727.88092.0686 1.72413-1.5093 1.88337-3.5243z"></path>
        <path 
            d="m11.4253 5.17627c.5574-.68068-.2717-2.26608-1.85181-3.54111-1.58013-1.275018-3.31295-1.756832-3.87036-1.076157-.55741.680677.27167 2.266077 1.8518 3.541097 1.58013 1.27503 3.31297 1.75684 3.87037 1.07617z"></path>
    </g>
</svg>;


export default () => {
    const {t} = useTranslation('leaders');
    const {language } = useI18next();
  library.add(fas)

  return (
    <section id="pricing">
      <div className="row section-head" style={{maxWidth: '970px'}}>
        <h2 style={{fontSize: '4rem'}}>{ t(content.title) }</h2>
      </div>

      <div className="row" style={{paddingBottom: '0px'}}>
        <div style={{display: 'contents'}} className="pricing-tables bgrid-thirds s-bgrid-halves">
          { content.leaders.map((leader, index) =>
            <div className="column" key={index}>
              <div className="card-analyst">
                  <div style={{display: 'flex',  flexFlow: 'row wrap'
                  }}>
                      <div style={{flex: '1 1 0px', transform: 'scaleX(-1)'}}>
                          <SvgElement />
                      </div>
                      <p className={`${language === 'sl' ? 'sl-leader-title' : 'en-leader-title'}`} style={{flex: '0 0 auto', fontWeight: 'bold', padding: '0 8px', color: 'white'}}>{t(leader.cardTitle)}</p>
                      <div style={{    flex: '1 1 0px'}} >
                          <SvgElement />
                      </div>
                  </div>
                  <div style={{marginTop: '16px', alignItems: 'center', display: 'flex', flexFlow: 'row wrap'}}>
                      <div style={{maxWidth: '40%',minHeight: '0px', minWidth: '0px', textAlign:'right', paddingRight: '0.9375rem', paddingLeft: '0.9375rem', flex: '1 1 0px'}}>
                          <img style={{display:'inline-block'}} src={leader.logo} alt={'logo'} />
                      </div>
                     <h3 style={{borderLeft: '1px solid white',     paddingLeft: '10px', textAlign: 'left', color: '#a1a1a1', flex: '1 1 0px', fontSize: '0.9rem', lineHeight: '1.5'}}>
                         {t(leader.description)}
                     </h3>
                  </div>
                  <div style={{display:'flex', justifyContent:'center'}}>
                      <a target="_blank" rel="noopener noreferrer" href={leader.url}
                         className="arrow-link" data-event="homepage">{t(leader.actionTitle)}
                          <svg className="arrow-svg" fill="none" height="20" viewBox="0 0 22 17" width="22"
                               xmlns="http://www.w3.org/2000/svg">
                              <path className="arrow-svg-path" fill="#77dd77    "
                                    d="m21.1095 9.47096c.4164-.41644.4164-1.09164 0-1.50808l-6.7864-6.78639c-.4165-.41645-1.0917-.41645-1.5081 0-.4164.41644-.4164 1.09164 0 1.50808l6.0323 6.03235-6.0323 6.03238c-.4165.4164-.4165 1.0916 0 1.5081.4164.4164 1.0916.4164 1.5081 0zm-20.964931.31234h20.210831v-2.13276h-20.210831z">
                              </path>
                          </svg>
                      </a>
                  </div>
              </div>
            </div>
          )
          }
        </div>
      </div>
    </section>
  )
}
