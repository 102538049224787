import React from "react"
import Slider from "react-slick"

import content from '../../content/blog.yaml';
import {useTranslation} from "react-i18next";
import {useI18next} from "gatsby-plugin-react-i18next";

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="next-arrow">
           <svg  onClick={onClick} className="chevron-svg" fill="none" height="70" viewBox="0 0 35 70" width="35"
             xmlns="http://www.w3.org/2000/svg">
            <path d="m34.6294 1.0249-33.908697 33.9087 33.908697 33.9087" stroke="#fff"></path>
            </svg>
    </div>
    );
}

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="prev-arrow">
        <svg  onClick={onClick} className="chevron-svg" fill="none" height="70" viewBox="0 0 35 70" width="35" xmlns="http://www.w3.org/2000/svg">
            <path d="m34.6294 1.0249-33.908697 33.9087 33.908697 33.9087" stroke="#fff"></path>
        </svg>
        </div>
    );
}

const MySlider = () =>{
    const {t} = useTranslation('blog');
    const {language } = useI18next();
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 7000,
        draggable: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <section id="testimonials">
            <div className="row content" style={{paddingBottom: 0, height: '100%'}}>
                <div className="text-container">
                    <div className="twelve columns flex-container">
                        <Slider {...settings} style={{ userSelect: 'text' }}>
                                { content.blogs.map((blog, index) => (
                                                <div className="column" key={index}>
                                                    <div className="additional-story grid-x align-middle white bg-blue cover" style={{backgroundImage: `url(${blog.backgroundImage})`}}>
                                                        <ul className="featured-post-tag">
                                                            {t(blog.badgeTitle)}
                                                        </ul>
    
                                                        <div className="relative">
                                                            <figure className="customerCard__logo"><img
                                                                src={blog.logo}
                                                                alt="" />
                                                            </figure>
                                                            <h3 className="heading5">{t(blog.description)}</h3>
                                                        </div>
    
                                                        <a target="_blank" rel="noopener noreferrer" href={blog.url}
                                                           className={`${language === 'sl' ? 'sl-arrow-link' : 'arrow-link'}`}
                                                           data-event="how-continental-replaced-lotus-notes-and-domino-with-low-code">{t(blog.actionTitle)}
                                                            <svg className="arrow-svg" fill="none" height="17" viewBox="0 0 22 17"
                                                                 width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path className="arrow-svg-path" fill="#ffffff"
                                                                      d="m21.1095 9.47096c.4164-.41644.4164-1.09164 0-1.50808l-6.7864-6.78639c-.4165-.41645-1.0917-.41645-1.5081 0-.4164.41644-.4164 1.09164 0 1.50808l6.0323 6.03235-6.0323 6.03238c-.4165.4164-.4165 1.0916 0 1.5081.4164.4164 1.0916.4164 1.5081 0zm-20.964931.31234h20.210831v-2.13276h-20.210831z">
                                                                </path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                        </div>
                                ))}
                          
                        </Slider>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default MySlider
