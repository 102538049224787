import React from "react"
import ScrollAnimation from 'react-animate-on-scroll'
import content from "../../content/section1.yaml"
import {useTranslation} from "react-i18next";

export default () => {
  const {t} = useTranslation('section1');

  return (
      <section id='features'>
        {content.map((value, index) => {

          let pos, imagepos

          pos = "left"
          imagepos = "right"


          let media
          if (value.image) {
            media = (<img style={{width: '400px', float: 'right'}} src={value.image} alt=""/>)
          }

          return (
              <div key={index} className={'row feature ' + t(value.name)}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
                     className={'six columns ' + pos}>
                  <h2 style={{fontSize: '4rem', lineHeight: '1.125', fontWeight: 'bold'}}>{t(value.title)}</h2>
                  <p style={{fontSize: '2.25rem', lineHeight: '1.5'}}>{t(value.body)}</p>
                  {/*<div className="buttons">*/}
                  {/*  <a className="btn-primary2" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}*/}
                  {/*     href={'https://www.mendix.com/solutions/'}>{t(value.buttonText)}</a>*/}
                  {/*</div>*/}
                </div>

                <ScrollAnimation
                    animateIn="pulse"
                    animateOnce={true}
                    initiallyVisible={true}
                    style={{display: 'flex', justifyContent: 'center'}}
                    className={'six columns feature-media ' + imagepos}
                >
                  {media}
                </ScrollAnimation>
              </div>
          )
        })}
      </section>
  )
}
